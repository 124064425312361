<template>
  <el-dialog
    :visible="visible"
    width="500px"
    custom-class="authenticationDialog"
    :close-on-click-modal="false"
    :before-close="handleClose">
    <span slot="title" class="title">
      <i class="el-icon-warning"/>
      {{title}}
    </span>
    <div class="content">
      <div class="item">当前用户手机： {{phone}}</div>
      <div class="item">
        <div class="label">验证码</div>
        <div class="code">
          <el-input v-model.trim="code" clearable/>
          <el-button @click="getCode">获取验证码</el-button>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose">取 消</el-button>
      <el-button size="small" type="primary" @click="handleSure">确认</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { verificationCode,reconfirm } from '@/api/clientManagement.js'
export default {
  name:'secondAuthentication',
  props: {
    authenticationVisible:{
      default: false
    },
    phone:{
      default: ''
    },
    title:{
      default: '确认重置'
    }
  },
  data(){
    return{
      code:''
    }
  },
  computed:{
    visible:{
      get(){
        return this.authenticationVisible
      },
      set(){}
    }
  },
  watch:{
    visible(){
      this.code = ''
    }
  },
  methods:{
    handleClose(){
      this.$emit('update:authenticationVisible',false)
    },
    handleSure(){
      if(this.code){
        let params = {
          service:'change_oauth_client',
          captcha:this.code
        }
        reconfirm(params).then(res=>{
          if(res.code==200){
            this.$emit('update:authenticationVisible',false)
            this.$emit('handleSure',this.title)
          }else{
            this.code = ''
            this.$message.warning(res.msg || '接口错误，请重试')
          }
        })
      }else{
        this.$message.warning('请填写验证码')
      }
    },
    getCode(){
      verificationCode().then(res=>{
        if(res.code != 200){
          this.$message.error(res.msg || '接口错误，请重试')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/element-reset.scss';
.authenticationDialog{
  color: #E8E8E8;
  .el-icon-warning{
    color: #E6A23C;
    margin-right: 5px;
    font-size: 24px;
  }
  .title{
    font-size: 20px;
  }
  .content{
    padding: 0px 30px 15px 30px;
    line-height: 40px;
  }
  .content{
    .code{
      display: flex;
      .el-button{
        margin-left: 10px;
        color:#E8E8E8
      }
    }
  }
}
</style>